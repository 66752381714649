import affiliateRoutesNames from './affiliate-route-names';
import layoutNames from './layout-names';

export default [
  {
    path: '/app/login-affiliate',
    name: affiliateRoutesNames.loginAffiliate,
    component: () => import(/* webpackChunkName: "login-affiliate" */ '../views/LoginAffiliate.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-login' },
  },
  {
    path: '/app/register-affiliate/1',
    name: affiliateRoutesNames.registerAffiliateStep1,
    component: () => import(/* webpackChunkName: "registerAffiliateStep1" */ '../views/RegisterAffiliate/RegisterAffiliateStep1.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-register-affiliate-step-1' },
  },
  {
    path: '/app/register-affiliate/2',
    name: affiliateRoutesNames.registerAffiliateStep2,
    component: () => import(/* webpackChunkName: "registerAffiliateStep2" */ '../views/RegisterAffiliate/RegisterAffiliateStep2.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-register-affiliate-step-2' },
  },
  {
    path: '/app/register-affiliate/3',
    name: affiliateRoutesNames.registerAffiliateStep3,
    component: () => import(/* webpackChunkName: "registerAffiliateStep3" */ '../views/RegisterAffiliate/RegisterAffiliateStep3.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-register-affiliate-step-3' },
  },
  {
    path: '/app/register-affiliate-payment',
    name: affiliateRoutesNames.registerAffiliatePayment,
    component: () => import(/* webpackChunkName: "registerAffiliateStep4" */ '../views/RegisterAffiliate/RegisterAffiliatePayment.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-register-affiliate-payment' },
  },
  {
    path: '/app/affiliate/dashboard',
    name: affiliateRoutesNames.dashboard,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/affiliate/DashboardAffiliate.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.affiliate },
  },
  {
    path: '/app/affiliate/profile',
    name: affiliateRoutesNames.profile,
    component: () => import(/* webpackChunkName: "profile" */ '../views/affiliate/Profile.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.affiliate },
  },
  {
    path: '/app/affiliate/change-bank-details',
    name: affiliateRoutesNames.changeBankDetails,
    component: () => import(/* webpackChunkName: "changeBankDetails" */ '../views/affiliate/ChangeBankDetails.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.affiliate },
  },
];
