import { format } from 'date-fns';

import { CandidateShopItem, GroupedBySearchJob } from '../interfaces/candidate-shop-item';

export default function groupBySearchJobName(candidates: CandidateShopItem[]): GroupedBySearchJob[] {
  // Sort candidates by purchase date (most recent first)
  const sortedCandidates = [...candidates].sort((a, b) => {
    const dateA = a.purchasedDate ? new Date(a.purchasedDate).getTime() : 0;
    const dateB = b.purchasedDate ? new Date(b.purchasedDate).getTime() : 0;
    return dateB - dateA; // Descending order (most recent first)
  });

  // First group by job
  const jobGroups: { [key: string]: CandidateShopItem[]; } = {};

  for (const candidate of sortedCandidates) {
    const jobName = candidate.matchingSearchedJob?.job?.name || 'Sans emploi';

    if (!jobGroups[jobName]) {
      jobGroups[jobName] = [];
    }

    jobGroups[jobName].push(candidate);
  }

  // Get sorted job names
  const sortedJobNames = Object.keys(jobGroups).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  // Then group by date for each job
  const result: GroupedBySearchJob[] = [];

  // Use sorted job names to create the result array
  for (const jobName of sortedJobNames) {
    const jobCandidates = jobGroups[jobName];
    const dateGroups: { [key: string]: CandidateShopItem[]; } = {};

    for (const candidate of jobCandidates) {
      const date = candidate.purchasedDate ? format(new Date(candidate.purchasedDate), 'dd/MM/yyyy') : '';

      if (!dateGroups[date]) {
        dateGroups[date] = [];
      }

      dateGroups[date].push(candidate);
    }

    result.push({
      searchedJobName: jobName,
      dateGroups: Object.entries(dateGroups).map(([date, dateCandidates]) => ({
        purchasedDate: date,
        candidates: dateCandidates,
      })),
    });
  }

  return result;
}
