<template>
  <div class="cart-details">
    <div v-for="(jobGroup, index) in candidatesBySearchedJob" :key="index" class="candidates-group">
      <h3 class="h5">{{ jobGroup.searchedJobName }}</h3>
      <div v-for="(dateGroup, dateIndex) in jobGroup.dateGroups" :key="dateIndex">
        <div class="candidates-grid">
          <div
            v-for="(candidate, i) in dateGroup.candidates"
            :key="i"
            class="candidate-card-cart"
          >
            <v-candidate-card
              :basket="false"
              :favorite="actions"
              :candidate="candidate"
              @refresh-resumes="$emit('refresh-resumes');"
            />
            <button
              v-if="actions"
              type="button"
              class="delete-candidate"
              @click="$emit('delete-candidate', candidate)"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { GroupedBySearchJob } from '../interfaces/candidate-shop-item';

export default defineComponent({
  name: 'ShoppingListItemsResume',
  props: {
    candidatesBySearchedJob: {
      type: Object as PropType<GroupedBySearchJob[]>,
      required: true,
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete-candidate', 'refresh-resumes'],
});
</script>
