/**
 * Vuex store module for managing the resume shop functionality
 * Handles shopping cart operations for candidate resumes and related products
 */

import Cookies from 'js-cookie';
import { ActionContext } from 'vuex';

import { CookiesListNames } from '@/js/enums/cookies-list-names';
import { StripesProductsNames } from '@/js/enums/stripe-products-ids';
import { CandidateShopItem } from '@/js/interfaces/candidate-shop-item';
import Product from '@/js/interfaces/product';

import State from '../state';

/**
 * Interface defining the resume shop state structure
 */
interface ResumeShopState {
  shoppingList: CandidateShopItem[]; // List of items in the shopping cart
  shoppingListUuids: string[]; // UUIDs of items in the shopping cart
  resumesFilterCount: number; // Count of filtered resumes
  products: Product[]; // Available products in the shop
}

/**
 * Default state for the resume shop module
 */
const defaultState: ResumeShopState = {
  shoppingList: [],
  resumesFilterCount: 0,
  products: [],
  shoppingListUuids: [],
};

// List of valid Stripe product IDs for resumes
// eslint-disable-next-line unicorn/prefer-set-has
const stripesResumeProductsIds: string[] = [
  StripesProductsNames.regularCandidate,
  StripesProductsNames.rareGemCandidate,
];

type Context = ActionContext<ResumeShopState, State>;

/**
 * Mutations for updating the resume shop state
 */
const mutations = {
  /**
   * Updates the list of shopping cart item UUIDs
   */
  setShoppingListUuids(state: ResumeShopState, shoppingListUuids: string[]) {
    state.shoppingListUuids = shoppingListUuids;
  },

  /**
   * Toggles a shopping item in the cart by UUID
   * Adds if not present, removes if present
   */
  setUuidItemInShoppingList(state: ResumeShopState, shoppingItem: CandidateShopItem) {
    const candidateIndex = state.shoppingListUuids.indexOf(shoppingItem.uuid);
    // eslint-disable-next-line unicorn/prefer-ternary
    if (candidateIndex === -1) {
      state.shoppingListUuids = [...state.shoppingListUuids, shoppingItem.uuid];
    } else {
      state.shoppingListUuids = state.shoppingListUuids.filter(
        (uuid) => uuid !== shoppingItem.uuid,
      );
    }
  },

  /**
   * Removes multiple items from the shopping cart
   */
  deleteUuidItemFromShoppingList(state: ResumeShopState, candidates: CandidateShopItem[]) {
    const candidatesUuid = new Set(candidates.map((c) => c.uuid));
    state.shoppingListUuids = state.shoppingListUuids.filter(
      (uuid) => !candidatesUuid.has(uuid),
    );
  },

  /**
   * Updates the available products list
   */
  setProducts(state: ResumeShopState, products: Array<Product>) {
    state.products = products;
  },

  /**
   * Updates the count of filtered resumes
   */
  setResumesFilterCount(state: ResumeShopState, count: number) {
    state.resumesFilterCount = count;
  },

  /**
   * Resets the shopping list
   */
  resetShoppingList(state: ResumeShopState) {
    state.resumesFilterCount = 0;
    state.shoppingList = [];
    state.shoppingListUuids = [];
  },
};

/**
 * Getters for computing derived state
 */
const getters = {
  /** Returns the number of items in the shopping cart */
  basketItemsCount: (state: ResumeShopState): number => state.shoppingListUuids.length,
  /** Checks if the shopping cart has any items */
  isBasketEmpty: (state: ResumeShopState): boolean => state.shoppingListUuids.length > 0,
  /** Returns all candidate-related products */
  candidateGoods: (state: ResumeShopState) => state.products.filter((product) => stripesResumeProductsIds.includes(product.name)),
};

/**
 * Actions for handling async operations and complex mutations
 */
const actions = {
  /**
   * Toggles an item in the shopping cart and updates the cookie
   */
  setUuidItemInShoppingList(context: Context, shoppingItem: CandidateShopItem) {
    context.commit('setUuidItemInShoppingList', shoppingItem);
    Cookies.set(
      CookiesListNames.candidatesShoppingList,
      JSON.stringify(context.state.shoppingListUuids),
      { secure: true, sameSite: 'strict', expires: 14 },
    );
  },

  /**
   * Updates the shopping cart UUIDs list
   */
  setShoppingListUuids(context: Context, shoppingListUuids: string[]) {
    context.commit('setShoppingListUuids', shoppingListUuids);
  },

  /**
   * Removes items from the shopping cart and updates the cookie
   */
  deleteUuidItemFromShoppingList(context: Context, candidates: CandidateShopItem[]) {
    context.commit('deleteUuidItemFromShoppingList', candidates);
    Cookies.set(
      CookiesListNames.candidatesShoppingList,
      JSON.stringify(context.state.shoppingListUuids),
      { secure: true, sameSite: 'strict', expires: 14 },
    );
  },

  /**
   * Resets the shopping list
   */
  resetShoppingList(context: Context) {
    context.commit('resetShoppingList');
    Cookies.remove(CookiesListNames.candidatesShoppingList);
  },
};

/**
 * Resume shop Vuex module definition
 */
const resumeShop = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default resumeShop;
