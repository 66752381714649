<template>
  <div :class="{ 'affiliate-info': !isHeadHunter && !isPremium }">
    <a href="" :class="{ 'affiliate-info-link': !isHeadHunter && !isPremium }" @click.prevent="modalOpened = true;" @keyup.e.enter.prevent="modalOpened = true;">
      {{ $t('components.change_password_form.cta') }}
      <v-sprite v-if="!isHeadHunter && !isPremium" color="currentColor" class="icon" icon="right-arrow" />
    </a>

    <v-modal :id="'changePasswordModal'" v-model="modalOpened">
      <form @submit.prevent="submitChangePassword">
        <div class="form-group form-group-required" :class="{ 'is-invalid': oldPassword && v.oldPassword.$invalid }">
          <label class="form-label" for="old-password">{{ $t('components.change_password_form.old_password') }}</label>
          <v-password-field
            :id="'old-password'"
            v-model.trim="oldPassword"
            :name="'old-password'"
            :input-class="{ 'is-invalid': oldPassword && v.oldPassword.$invalid }">
            <div v-if="oldPassword && !v.oldPassword.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
          </v-password-field>
        </div>

        <div class="form-group form-group-required" :class="{ 'is-invalid': newPassword && v.newPassword.$invalid }">
          <label class="form-label" for="new-password">{{ $t('components.change_password_form.new_password') }}</label>
          <v-password-field
            :id="'new-password'"
            v-model.trim="newPassword"
            :name="'new-password'"
            :input-class="{ 'is-invalid': newPassword && v.newPassword.$invalid }">
            <div v-if="newPassword && !v.newPassword.minLength" class="invalid-feedback">
              {{ $t('form.validation.min_length') }}
            </div>
          </v-password-field>

          <div class="form-text">{{ $t('form.helper.password') }}</div>
        </div>

        <div class="form-group form-group-required" :class="{ 'is-invalid': newPasswordRepeat && v.newPasswordRepeat.$invalid }">
          <label class="form-label" for="new-password-repeat">{{ $t('components.change_password_form.new_password_repeat') }}</label>
          <v-password-field
            :id="'new-password-repeat'"
            v-model.trim="newPasswordRepeat"
            :name="'new-password-repeat'"
            :input-class="{ 'is-invalid': newPasswordRepeat && v.newPasswordRepeat.$invalid }">
            <div v-if="newPasswordRepeat && !v.newPasswordRepeat.sameAsPassword" class="invalid-feedback">
              {{ $t('form.validation.same_as_password') }}
            </div>
          </v-password-field>
        </div>

        <div class="d-grid mb-3">
          <button :disabled="v.$invalid" type="submit" class="btn btn-primary">{{ $t('actions.change_password') }}</button>
        </div>
      </form>
    </v-modal>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { minLength, required, sameAs } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapGetters } from 'vuex';

import { ChangePasswordPayload } from '../interfaces/payload';

export default defineComponent({
  name: 'ChangePasswordForm',
  props: {},
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      modalOpened: false,
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    };
  },
  validations() {
    return {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      newPasswordRepeat: {
        required,
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },
  computed: {
    ...mapGetters('recruiter', [
      'isPremium',
      'isHeadHunter',
    ]),
  },
  methods: {
    ...mapActions('recruiter', [
      'changePassword',
    ]),
    async submitChangePassword() {
      try {
        const payload: ChangePasswordPayload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        await this.changePassword(payload);

        this.toast.success((this.$t('components.change_password_form.success') as string));
        this.modalOpened = false;
      } catch (error) {
        if (error.name === 'HTTPError') {
          const data = error.response.json();

          if (error.response && 'oldPassword' in data) {
            this.toast.error(data.oldPassword.join(', '));
          }
        } else {
          this.toast.error((this.$t('components.change_password_form.error') as string));
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.classic-dashboard a {
  font-weight: 500;
  text-decoration: none;
}
</style>
