<template>
  <div class="register-information-detail">
    <div class="form-group form-group-required">
      <label for="email" class="form-label">{{ $t('components.register_information.email.label') }}</label>
      <input
        id="email"
        v-model="localAffiliatePayload.email"
        type="email"
        class="form-control"
        name="email"
        placeholder="mail@entreprise.com"
        :class="{ 'is-invalid': localAffiliatePayload.email && v.localAffiliatePayload.email.$invalid }"
        @change="localSetAffiliate()">
      <div v-if="localAffiliatePayload.email && !v.localAffiliatePayload.email.required" class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
      <div v-if="!v.localAffiliatePayload.email.email" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
    </div>

    <div class="form-group form-group-required">
      <label for="password" class="form-label">{{ $t('components.register_information.password.label') }}</label>
      <v-password-field
        :id="'password'"
        v-model.trim="localAffiliatePayload.password"
        name="password"
        :input-class="{ 'is-invalid': localAffiliatePayload.password && v.localAffiliatePayload.password.$invalid }"
        @input="localSetAffiliate()" />
      <div
        v-if="localAffiliatePayload.password && !v.localAffiliatePayload.password.required"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
      <div
        v-if="localAffiliatePayload.password && !v.localAffiliatePayload.password.minLength"
        class="invalid-feedback">
        {{ $t('form.validation.min_length') }}
      </div>
      <small class="form-text text-xs text-muted">{{ $t('form.helper.password') }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import {
  email, minLength, required,
} from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { RecruiterAffiliatePayload } from '../interfaces/affiliate-register-payload';

export default defineComponent({
  name: 'RegisterAffiliateInformation',
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      localAffiliatePayload: {} as RecruiterAffiliatePayload,
    };
  },
  computed: {
    ...mapState('register', [
      'recruiterAffiliatePayload',
    ]),
  },
  mounted(): void {
    this.localAffiliatePayload = cloneDeep(this.recruiterAffiliatePayload);
  },
  validations() {
    return {
      localAffiliatePayload: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
        },
      },
    };
  },
  methods: {
    ...mapActions('register', [
      'setAffiliatePayload',
    ]),
    async localSetAffiliate(): Promise<void> {
      await this.setAffiliatePayload(cloneDeep(this.localAffiliatePayload));
    },
  },
});

</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-information-detail {
  margin-top: 32px;

  @include mq($from: tablet) {
    margin-top: 62px;
  }
}

.form-group {
  .text-muted {
    display: block;
    text-align: right;
  }
}
</style>
