<template>
  <div class="app-affiliate-layout">
    <v-affiliate-mobile-header @open-modal="openModal" />

    <div class="app-affiliate-content">
      <div class="app-affiliate-sidebar d-none d-xl-block">
        <div class="sticky-sidebar">
          <router-link :to="{ name: affiliateRoutesNames.dashboard }">
            <img class="sidebar-logo" src="@/images/decide-logo.svg" alt="" />
          </router-link>
          <v-affiliate-navigation @open-modal="openModal" />
        </div>
      </div>
      <slot />
    </div>

    <v-modal :id="'confirmLogoutModal'" v-model="showModal">
      <h5 class="text-center">{{ $t('modals.confirm_logout.title') }}</h5>
      <div class="d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-rounded btn-light m-2 cancel-logout-btn" @click.prevent="showModal = false">
          {{ $t('actions.cancel') }}
        </button>
        <button type="button" class="btn btn-rounded btn-primary btn-logout m-2 logout-btn" @click.prevent="logoutUser">
          {{ $t('components.app_navigation.logout') }}
        </button>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import affiliateRoutesNames, { AffiliateRoutesNames } from '../router/affiliate-route-names';

export default defineComponent({
  name: 'AppAffiliateLayout',
  setup() {
    return {
      affiliateRoutesNames,
    };
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    affiliateRouteNames(): AffiliateRoutesNames {
      return affiliateRoutesNames;
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'logout',
    ]),
    openModal() {
      this.showModal = true;
    },
    logoutUser() {
      this.logout();
      this.showModal = false;
      this.$router.push('/');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-affiliate-layout {
  background-color: #f5f4fb;
  min-height: 100vh;
  padding-top: 120px;

  @include mq($from: wide) {
    padding-top: 0;
  }
}

.app-affiliate-content {
  max-width: 100%;
  padding-inline: 20px;
  width: 1440px;

  @include mq($from: wide) {
    display: grid;
    grid-template-columns: 300px minmax(0, 1fr);
    min-height: 100vh;
    padding-inline: 0;
  }
}

.app-affiliate-sidebar {
  background-color: $gray-900;
}

.sticky-sidebar {
  padding: 20px;
  position: sticky;
  top: 0;
}

.sidebar-logo {
  margin-block: 32px 60px;
  width: 100%;
}
</style>
