import Fingerprint from './fingerprint';

const PATH_PATTERN = /\/address\/[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}\/\?type=(candidate|recruiter)$/;

const fingerprint = Fingerprint.generate();
const apiUrl = process.env.VUE_APP_API_URL;

function shouldRunInit(): boolean {
  return PATH_PATTERN.test(window.location.href);
}

async function postFingerprint(): Promise<void> {
  const payload = {
    fingerprint,
    address: window.location.pathname.split('/')[2],
  };

  try {
    await window.ky.post('/api/fingerprint/', { json: payload }).json<void>();
  } catch (error) {
    throw new Error(`HTTP Error: ${error}`);
  }
}

function init(): void {
  if (!shouldRunInit()) {
    return;
  }

  if (fingerprint && apiUrl) {
    postFingerprint();
  }
}

init();
