<template>
  <div class="order-summary">
    <h2 class="order-summary-title">{{ $t('components.resume_affiliate_kit_order.kit_payment_title') }}</h2>
    <div class="order-summary-content">
      <div class="order-rows">
        <div class="order-row">
          <strong class="order-label">{{ $t('components.resume_affiliate_kit_order.kit_base') }}</strong>
          <span class="order-value">
            <strong>{{ kitSubscriptionResume.regularSubscriptionPrice }}€</strong><br>
            <span class="kit-info">{{ $t('components.resume_affiliate_kit_order.per_year_excl_tax') }}</span>
          </span>
        </div>
        <div v-if="kitSubscriptionResume.postersQuantity > 0" class="order-row bordered">
          <span class="order-label">
            {{ kitSubscriptionResume.postersQuantity }}
            {{ $t('components.resume_affiliate_kit_order.additional_posters') }}
          </span>
          <span class="order-value">{{ kitSubscriptionResume.currentAdditionalPosterAmount }}€</span>
        </div>
        <div v-if="kitSubscriptionResume.stickersQuantity > 0" class="order-row bordered">
          <span class="order-label">
            {{ kitSubscriptionResume.stickersQuantity }}
            {{ $t('components.resume_affiliate_kit_order.additional_stickers') }}
          </span>
          <span class="order-value">{{ kitSubscriptionResume.currentAdditionalStickerAmount }}€</span>
        </div>
        <div class="order-row bordered">
          <span class="order-label">{{ $t('components.resume_affiliate_kit_order.total_excl_tax') }}</span>
          <span class="order-value">{{ kitSubscriptionResume.totalResumeWithoutTaxes }}€</span>
        </div>
        <div class="order-row tax">
          <span class="order-label">{{ $t('components.resume_affiliate_kit_order.vat_20_percent') }}</span>
          <span class="order-value">{{ kitSubscriptionResume.vatAmount }}€</span>
        </div>
        <div class="order-row bordered">
          <strong class="order-label">{{ $t('components.resume_affiliate_kit_order.total_incl_tax') }}</strong>
          <strong class="order-value">{{ kitSubscriptionResume.totalResumeWithTaxes }}€</strong>
        </div>
      </div>

      <div class="form-check">
        <input
          id="use_condition"
          v-model="useCondition"
          class="form-check-input "
          type="checkbox"
          role="switch"
          :aria-checked="useCondition"
          @change="localOnChangeUseCondition"
        >
        <label class="form-label" for="use_condition">
          {{ $t('views.register_affiliate_payment.use_condition') }}
          <a href="/conditions-generales/" target="_blank">{{ $t('views.register_affiliate_payment.use_condition_link') }}</a>
        </label>
      </div>

      <button type="button" class="btn btn-rounded btn-primary w-100" :disabled="disabled" @click.prevent="$emit('validate-order')">
        {{ $t('components.resume_affiliate_kit_order.validate_order') }}
      </button>
    </div>

    <div class="extra-infos">
      <h4 class="h4">{{ $t('components.resume_affiliate_kit_order.become_local_employment_actor') }}</h4>
      <span class="underline" />
      <ul class="extra-infos-list">
        <li>
          <v-sprite color="var(--bs-primary)" icon="check" />
          {{ $t('components.resume_affiliate_kit_order.enhance_visibility') }}
        </li>
        <li>
          <v-sprite color="var(--bs-primary)" icon="check" />
          {{ $t('components.resume_affiliate_kit_order.promote_local_networking') }}
        </li>
        <li>
          <v-sprite color="var(--bs-primary)" icon="check" />
          {{ $t('components.resume_affiliate_kit_order.generate_new_revenue') }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';

import { RecruiterAffiliatePayload } from '@/js/interfaces/affiliate-register-payload';

import { AffiliateSubscriptionResume } from '../interfaces/affiliate-subscription-resume';

export default defineComponent({
  name: 'ResumeAffiliateKitOrder',
  props: {
    kitSubscriptionResume: {
      type: Object as PropType<AffiliateSubscriptionResume>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['validate-order'],
  computed: {
    ...mapState('register', [
      'recruiterAffiliatePayload',
    ]),
  },
  data() {
    return {
      useCondition: false,
    };
  },
  validations() {
    return {
      localAffiliatePayload: {
        useCondition: {
          required,
        },
      },
    };
  },
  methods: {
    ...mapActions('register', [
      'setAffiliatePayload',
    ]),
    localOnChangeUseCondition(): void {
      const localAffiliatePayload = {
        ...cloneDeep(this.recruiterAffiliatePayload),
        useCondition: this.useCondition,
      };

      this.setAffiliatePayload(localAffiliatePayload);
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.order-summary {
  margin-block: 32px;

  @include mq($from: desktop) {
    background-color: $pink-200;
    margin-block: 0;
    padding-left: 30px;
  }

  @include mq($from: wide) {
    justify-self: end;
    max-width: 464px;
    padding-left: 64px;
  }
}

.order-summary-title {
  display: none;
  font-size: 32px;
  text-align: center;

  @include mq($from: desktop) {
    display: block;
    margin-block: 64px 41px;
  }
}

.order-summary-content {
  @include mq($from: desktop) {
    background-color: $white;
    border: 1px solid $purple-200;
    border-radius: 8px;
    padding: 20px;
  }
}

.order-rows {
  background-color: $white;
  border: 1px solid $purple-200;
  border-radius: 8px;
  padding: 20px;

  @include mq($from: desktop) {
    all: unset;
  }
}

.order-row {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  gap: 16px;
  justify-content: space-between;
  line-height: 1em;

  &.tax {
    font-weight: 400;
    margin-top: 8px;
  }

  &.bordered {
    border-top: 1px solid $purple-200;
    margin-top: 16px;
    padding-top: 16px;
  }
}

.order-value {
  text-align: center;

  .kit-info {
    font-size: 16px;
    font-weight: 400;
  }
}

.form-check {
  line-height: 1.2em;
  margin-block: 32px;
  padding-left: 40px;

  .form-check-input {
    margin-left: -40px;
  }

  a {
    color: $body-color;
    font-weight: 700;
    text-decoration: none;
  }
}

.extra-infos {
  display: none;

  @include mq($from: desktop) {
    display: block;
    margin-top: 56px;
  }

  .h4 {
    color: $pink-700;
    font-size: 32px;
    line-height: 1em;
    text-align: center;
  }

  .underline {
    background-color: $primary;
    display: block;
    height: 1px;
    margin: 28px auto;
    width: 255px;
  }
}

.extra-infos-list {
  list-style-type: none;
  margin: 0;
  padding-left: 50px;

  li {
    align-items: center;
    display: grid;
    font-size: 14px;
    gap: 11px;
    grid-template-columns: 13px minmax(0, 1fr);
    line-height: 20px;

    + li {
      margin-top: 10px;
    }

    svg {
      width: 13px;
    }
  }
}
</style>
