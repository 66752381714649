<script lang="ts">

import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { LayoutNames } from '../router/layout-names';

export default defineComponent({
  name: 'AppLayout',
  setup() {
    const route = useRoute();
    /**
     *  This is a computed property that will return the name
     *  of the current route
     */
    const layout = computed(() => {
      const layoutName = route?.meta?.layout as LayoutNames;

      if (layoutName) {
        return `App${layoutName}Layout`;
      }
      return 'div';
    });

    return {
      layout,
    };
  },
});
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
