import layoutNames from './layout-names';
import routesNames from './route-names';

export default [
  {
    path: '/app/register',
    name: routesNames.register,
    component: () => import(/* webpackChunkName: "register" */ '../views/Register/Register.vue'),
    meta: { anonymousOnly: true },
  },
  {
    path: '/app/register/1',
    name: routesNames.registerStep1,
    component: () => import(/* webpackChunkName: "registerStep1" */ '../views/Register/RegisterStep1.vue'),
    meta: { anonymousOnly: true, bodyClass: 'view-register-step-1' },
  },
  {
    path: '/app/register/2',
    name: routesNames.registerStep2,
    component: () => import(/* webpackChunkName: "registerStep2" */ '../views/Register/RegisterStep2.vue'),
    meta: { anonymousOnly: false, bodyClass: 'view-register-step-2' },
  },
  {
    path: '/app/register/3',
    name: routesNames.registerStep3,
    component: () => import(/* webpackChunkName: "registerStep3" */ '../views/Register/RegisterStep3.vue'),
    meta: { anonymousOnly: false, bodyClass: 'view-register-step-3' },
  },
  {
    path: '/app/register/4',
    name: routesNames.registerStep4,
    component: () => import(/* webpackChunkName: "registerStep3" */ '../views/Register/RegisterStep4.vue'),
    meta: { anonymousOnly: false, bodyClass: 'view-register-step-4' },
  },
  {
    path: '/app/register/success',
    name: routesNames.registerSuccess,
    component: () => import(/* webpackChunkName: "registerSuccess" */ '../views/Register/RegisterSuccess.vue'),
    meta: { requiresAuth: true, bodyClass: 'view-register-success' },
  },
  {
    path: '/app/dashboard',
    name: routesNames.dashboard,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/jobs',
    name: routesNames.jobs,
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/brand',
    name: routesNames.brand,
    component: () => import(/* webpackChunkName: "brand" */ '../views/Brand.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/brand/preview',
    name: routesNames.brandPreview,
    component: () => import(/* webpackChunkName: "brandPreview" */ '../views/BrandPreview.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/resumes',
    component: () => import(/* webpackChunkName: "resumes" */ '../views/Resume.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
    children: [
      {
        path: '/',
        name: routesNames.resumes,
        component: () => import(/* webpackChunkName: "resumes" */ '../views/ResumeList.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
      {
        path: ':uuid',
        name: routesNames.candidateProfile,
        component: () => import(/* webpackChunkName: "candidate-profile" */ '../views/ResumeDetail.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
    ],
  },
  {
    path: '/app/resumes-shop',
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
    children: [
      {
        path: '',
        name: routesNames.resumesShop,
        component: () => import(/* webpackChunkName: "resumes-shop" */ '../views/ResumeShop.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
      {
        path: ':uuid',
        name: routesNames.candidateResumeDetail,
        component: () => import(/* webpackChunkName: "candidate-resume-detail" */ '../views/CandidateResumeDetail.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
    ],
  },
  {
    path: '/app/public-resume/:uuid',
    component: () => import(/* webpackChunkName: "public-resume" */ '../views/PublicResume.vue'),
    meta: { requiresAuth: false, needLayout: false },
  },
  {
    path: '/app/account',
    name: routesNames.account,
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/addresses',
    name: routesNames.addresses,
    component: () => import(/* webpackChunkName: "addresses" */ '../views/Addresses.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/addresses/add',
    name: routesNames.addNewAddress,
    component: () => import(/* webpackChunkName: "add-new-address" */ '../views/AddNewAddress.vue'),
    meta: { requiresAuth: true, needLayout: false },
  },
  {
    path: '/app/recruiter/reset',
    name: routesNames.reset,
    component: () => import(/* webpackChunkName: "reset" */ '../views/Reset.vue'),
    meta: { requiresAuth: true, needLayout: false },
  },
  {
    path: '/app/addresses/:uuid/complete',
    name: routesNames.addressComplete,
    component: () => import(/* webpackChunkName: "address-complete" */ '../views/AddressComplete.vue'),
    meta: { requiresAuth: false, needLayout: false },
  },
  {
    path: '/app/my-qr-codes',
    name: routesNames.myQrCodes,
    component: () => import(/* webpackChunkName: "my-qr-codes" */ '../views/MyQrCodes.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/add-address-job/1',
    name: routesNames.addAddressJobStep1,
    component: () => import(/* webpackChunkName: "addAddressJobStep1" */ '../views/AddressJob/AddressJobStep1.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/add-address-job/2',
    name: routesNames.addAddressJobStep2,
    component: () => import(/* webpackChunkName: "addAddressJobStep2" */ '../views/AddressJob/AddressJobStep2.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/add-address-job/3',
    name: routesNames.addAddressJobStep3,
    component: () => import(/* webpackChunkName: "addAddressJobStep3" */ '../views/AddressJob/AddressJobStep3.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/shopping-basket',
    name: routesNames.shoppingBasket,
    component: () => import(/* webpackChunkName: "shoppingBasket" */ '../views/ShoppingBasket.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/shopping-order-confirmed/:uuid',
    name: routesNames.shoppingBasketConfirmed,
    component: () => import(/* webpackChunkName: "shoppingBasketConfirmed" */ '../views/ShoppingOrderConfirmed.vue'),
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
  },
  {
    path: '/app/edit-address-job/:uuid',
    meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
    children: [
      {
        path: '1',
        name: routesNames.editAddressJobStep1,
        component: () => import(/* webpackChunkName: "editAddressJobStep1" */ '../views/AddressJob/AddressJobStep1.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
      {
        path: '2',
        name: routesNames.editAddressJobStep2,
        component: () => import(/* webpackChunkName: "editAddressJobStep2" */ '../views/AddressJob/AddressJobStep2.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
      {
        path: '3',
        name: routesNames.editAddressJobStep3,
        component: () => import(/* webpackChunkName: "editAddressJobStep2" */ '../views/AddressJob/AddressJobStep3.vue'),
        meta: { requiresAuth: true, needLayout: true, layout: layoutNames.default },
      },
    ],
  },
];
