<script lang="ts">
import Cookies from 'js-cookie';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { CookiesListNames } from '../enums/cookies-list-names';
import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'AppheaderBasket',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('resumeShop', [
      'basketItemsCount',
      'isBasketEmpty',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    isOnShoppingBasket(): boolean {
      return this.$route.name === this.routeNames.shoppingBasket;
    },
  },
  mounted() {
    this.handleShoppingListCookies();
  },
  methods: {
    ...mapActions('resumeShop', [
      'setShoppingListUuids',
    ]),
    handleShoppingListCookies() {
      const shoppingList = Cookies.get(CookiesListNames.candidatesShoppingList);
      if (shoppingList) {
        this.setShoppingListUuids(JSON.parse(shoppingList));
      }
    },
  },
});
</script>
<template>
  <div class="app-header-basket">
    <router-link class="d-flex" :to="{ name: routeNames.shoppingBasket }">
      <div class="basket-icon">
        <!-- to replace with the good icon --->
        <v-sprite v-if="isOnShoppingBasket" class="empty-basket-icon" color="#fff" icon="full-basket" />
        <v-sprite v-else class="empty-basket-icon" color="#fff" icon="basket" />
      </div>
      <span class="basket-count" :class="{ 'basket-is-full': isBasketEmpty }">
        {{ basketItemsCount }}
      </span>
    </router-link>
  </div>
</template>

<style lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-header-basket {
  display: flex;
  position: relative;

  &::after {
    @include mq($from: desktop) {
      background-color: $white;
      content: '';
      display: block;
      height: 40px;
      margin-inline: 30px 20px;
      opacity: .6;
      width: 1px;
    }
  }
}

.svg-basket.empty-basket-icon {
  @include mq($until: mobile) {
    height: 28px;
    width: 28px;
  }
}

.basket-count {
  align-items: center;
  background-color: $yellow;
  border-radius: 50%;
  bottom: 4px;
  color: $black;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  left: 16px;
  position: absolute;
  width: 24px;

  @include mq($from: mobile) {
    bottom: 18px;
    left: 26px;
  }
}
</style>
