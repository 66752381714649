<template>
  <div class="order-resume">
    <div class="order-details">
      <div class="order-details-row d-flex justify-content-between fw-bold">
        <span class="order-detail-row-name">{{ $t('views.shopping_basket.order_detail_row_name_candidates') }}</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.totalResumesCount }}</span>
      </div>
      <div
        v-for="(jobGroup, index) in candidatesBySearchedJob"
        :key="index"
        class="order-details-row d-flex justify-content-between">
        <span class="order-detail-row-name"> {{ jobGroup.searchedJobName }} </span>
        <span class="order-detail-row-value">{{ getTotalCandidatesForJob(jobGroup) }}</span>
      </div>
      <div class="order-details-row d-flex justify-content-between">
        <span class="order-detail-row-name">{{ $t('views.shopping_basket.order_detail_row_name_cv_price') }}</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.regularResumePrice }}€</span>
      </div>
      <div v-if="shoppingOrderResume.rareGemResumesCount > 0" class="order-details-row d-flex justify-content-between mb-3">
        <span class="order-detail-row-name">{{ $t('views.shopping_basket.order_detail_row_name_cv_rare_gem') }}</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.rareGemResumePrice }}€</span>
      </div>
      <hr>
      <div class="order-details-row d-flex justify-content-between ">
        <span class="order-detail-row-name">{{ shoppingOrderResume.regularResumesCount }} CV</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.regularResumesAmount }}€</span>
      </div>
      <div v-if="shoppingOrderResume.rareGemResumesCount > 0" class="order-details-row d-flex justify-content-between">
        <span class="order-detail-row-name">
          {{ shoppingOrderResume.rareGemResumesCount }}
          {{ $t('views.shopping_basket.order_detail_row_name_rare_gems') }}
        </span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.rareGemResumesAmount }}€</span>
      </div>
      <div class="order-details-row d-flex justify-content-between fw-bold">
        <span class="order-detail-row-name">Total HT</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.totalResumeWithoutTaxes }}€</span>
      </div>
      <div class="order-details-row d-flex justify-content-between">
        <span class="order-detail-row-name">TVA 20%</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.vatAmount }}€</span>
      </div>
      <hr>
      <div class="order-details-row total d-flex justify-content-between fw-bold">
        <span class="order-detail-row-name">Total (TTC)</span>
        <span class="order-detail-row-value">{{ shoppingOrderResume.totalResumeWithTaxes }}€</span>
      </div>
      <div class="order-resume-action">
        <slot name="action" />
      </div>
    </div>
    <div v-if="displayFooterInfos" class="order-reassurance">
      <div class="item">
        <v-sprite color="currentColor" icon="lock" />
        <p>{{ $t('views.shopping_basket.payment_security_notice') }}</p>
      </div>
      <div class="item">
        <v-sprite color="currentColor" icon="clock" />
        <p>{{ $t('views.shopping_basket.order_confirmation_notice') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { GroupedBySearchJob } from '../interfaces/candidate-shop-item';
import { ShoppingOrderResume } from '../interfaces/shopping-order-resume';

export default defineComponent({
  name: 'ShoppingOrderResume',
  props: {
    candidatesBySearchedJob: {
      type: Object as PropType<GroupedBySearchJob[]>,
      required: true,
    },
    shoppingOrderResume: {
      type: Object as PropType<ShoppingOrderResume>,
      required: true,
    },
    displayFooterInfos: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getTotalCandidatesForJob(jobGroup: GroupedBySearchJob): number {
      return jobGroup.dateGroups.reduce((total, dateGroup) => total + dateGroup.candidates.length, 0);
    },
  },
});
</script>
