<template>
  <button type="button" class="btn btn-white btn-lg btn-rounded" @click.prevent="downloadBothCodes">
    {{ $t('views.affiliate_dashboard.download_qrcode') }}
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { mapActions, useStore } from 'vuex';

import useQrCodeGetter from '../composition/qrcode-getter';

export default defineComponent({
  name: 'AffiliateQrCodeButton',
  setup() {
    const { downloadQrCode } = useQrCodeGetter();
    const store = useStore();
    const { recruiter } = store.state.recruiter.user;
    const addressUuid = ref('');

    return {
      recruiter,
      addressUuid,
      downloadQrCode,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions('recruiter', [
      'getProfile',
    ]),
    async fetchData() {
      await this.getProfile();

      const parentAddress = this.recruiter.addresses.find((address) => address.isParent);
      this.addressUuid = parentAddress ? parentAddress.uuid : this.recruiter.addresses[0].uuid;
    },
    async downloadBothCodes() {
      await this.downloadQrCode(true, this.addressUuid, 'recruiter');
      await this.downloadQrCode(true, this.addressUuid, 'candidate');
    },
  },
});
</script>
