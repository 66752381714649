export interface AffiliateRoutesNames {
  loginAffiliate: string;
  registerAffiliateStep1: string;
  registerAffiliateStep2: string;
  registerAffiliateStep3: string;
  registerAffiliatePayment: string;
  dashboard: string;
  profile: string;
  changeBankDetails: string;
}

const affiliateRoutesNames: Readonly<AffiliateRoutesNames> = {
  loginAffiliate: 'LoginAffiliate',
  registerAffiliateStep1: 'RegisterAffiliateStep1',
  registerAffiliateStep2: 'RegisterAffiliateStep2',
  registerAffiliateStep3: 'RegisterAffiliateStep3',
  registerAffiliatePayment: 'RegisterAffiliatePayment',
  dashboard: 'DashboardAffiliateView',
  profile: 'ProfileAffiliateView',
  changeBankDetails: 'ChangeBankDetailsAffiliateView',
};

export default affiliateRoutesNames;
