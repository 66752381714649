<template>
  <div class="affiliate-info">
    <a href="" class="affiliate-info-link" @click.prevent="modalOpened = true;" @keyup.e.enter.prevent="modalOpened = true;">
      {{ $t('components.change_siret_form.cta') }}
      <v-sprite color="currentColor" class="icon" icon="right-arrow" />
    </a>

    <v-modal :id="'changeSiretModal'" v-model="modalOpened">
      <form @submit.prevent="submitChangeSiret">
        <div class="form-group form-group-required" :class="{ 'is-invalid': newSiret && v.newSiret.$invalid }">
          <label class="form-label" for="new-siret">{{ $t('components.change_siret_form.new_siret') }}</label>
          <input
            :id="'new-siret'"
            v-model.trim="newSiret"
            :name="'new-siret'"
            class="form-control"
            :class="{ 'is-invalid': newSiret && v.newSiret.$invalid }">
          <div v-if="newSiret && !v.newSiret.isSiret" class="invalid-feedback">{{ $t('form.validation.isSiret') }}</div>
        </div>

        <div class="d-grid mb-3">
          <button :disabled="v.$invalid" type="submit" class="btn btn-primary">{{ $t('actions.change_siret') }}</button>
        </div>
      </form>
    </v-modal>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions } from 'vuex';

import { ChangeSiretPayload } from '../interfaces/payload';
import { exactLength } from '../services/custom-validations';

export default defineComponent({
  name: 'ChangeSiretForm',
  props: {},
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      modalOpened: false,
      newSiret: '',
    };
  },
  validations() {
    return {
      newSiret: {
        required,
        exactLength: exactLength(this.newSiret, 14),
      },
    };
  },
  methods: {
    ...mapActions('recruiter', [
      'changeSiret',
    ]),
    async submitChangeSiret() {
      try {
        const payload: ChangeSiretPayload = {
          newSiret: this.newSiret,
        };
        await this.changeSiret(payload);

        this.toast.success((this.$t('components.change_siret_form.success') as string));
        this.modalOpened = false;
      } catch (error) {
        if (error.name === 'HTTPError') {
          const data = error.response.json();

          if ('newSiret' in data) {
            this.toast.error(data.newSiret.join(', '));
          }
        } else {
          this.toast.error((this.$t('components.change_siret_form.error') as string));
        }
      }
    },
  },
});
</script>
