<template>
  <div v-if="action.name === actionTypesType.professionalKnowledge && action.active">
    <p class="form-title">
      {{ $t('views.add_new_address_job.address_job_step_3.professional_knowledge.title') }}
    </p>
    <p>
      {{ $t('views.add_new_address_job.address_job_step_3.professional_knowledge.subtitle_1') }}
    </p>
    <form
      class="professional-knowledge">
      <p class="form-subtitle">
        {{ $t('views.register_step_3.professional_knowledge.subtitle_1') }}
        <span>{{ $t('views.register_step_3.professional_knowledge.subtitle_1_span') }}</span>
      </p>
      <div v-if="professionalKnowledge" class="form-check-group">
        <div v-for="skill in professionalKnowledge" :key="skill.id" class="form-check">
          <label class="form-check-label custom-label" :for="`skill-${skill.id}`">
            <span class="name fw-bold">{{ skill.name }}: </span>
            <span class="description">{{ skill.description }}</span>
          </label>
          <input
            :id="`skill-${skill.id}`" v-model="selectedProfessionalKnowledge"
            class="form-check-input new-skill-radio" type="checkbox" :value="skill">
        </div>
      </div>
      <div v-if="v.selectedProfessionalKnowledge.maxLength.$invalid" class="invalid-message">
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('views.add_new_address_job.address_job_step_3.professional_knowledge.invalid_selections_knowledge') }}
        {{ selectedProfessionalKnowledge.length }}
      </div>
    </form>

    <form
      class="professional-softskill">
      <p class="form-subtitle">
        {{ $t('views.register_step_3.professional_knowledge.subtitle_2') }}
        <span>{{ $t('views.register_step_3.professional_knowledge.subtitle_2_span') }}</span>
      </p>
      <div v-if="professionalSoftSkills" class="form-check-group">
        <div v-for="skill in professionalSoftSkills" :key="skill.id" class="form-check">
          <label class="form-check-label custom-label" :for="`skill-${skill.id}`">
            <span class="name fw-bold">{{ skill.name }}: </span>
            <span class="description">{{ skill.description }}</span>
          </label>
          <input
            :id="`skill-${skill.id}`" v-model="selectedProfessionalSoftSkills"
            class="form-check-input new-skill-radio" type="checkbox" :value="skill">
        </div>
      </div>
      <div v-if="v.selectedProfessionalSoftSkills.maxLength.$invalid" class="invalid-message">
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('views.add_new_address_job.address_job_step_3.professional_knowledge.invalid_selections_soft_skill') }}
        {{ selectedProfessionalSoftSkills.length }}
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddAddressJob } from '../interfaces/address-job';
import { Skill } from '../interfaces/skill';

export default defineComponent({
  name: 'CandidateProfileProfessionalKnowledge',
  props: {
    professionalKnowledge: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    professionalSoftSkills: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedProfessionalKnowledge: [] as Skill[],
      selectedProfessionalSoftSkills: [] as Skill[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {
      selectedProfessionalKnowledge: { required, minLength: minLength(1), maxLength: maxLength(3) },
      selectedProfessionalSoftSkills: { required, minLength: minLength(1), maxLength: maxLength(3) },
    };
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedProfessionalKnowledge(): Promise<void> {
      await this.localOnSelectProfessionalKnowledge();
    },
    async selectedProfessionalSoftSkills(): Promise<void> {
      await this.localOnSelectProfessionalSoftSkills();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = this.addressJob;
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectProfessionalKnowledge(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        professionalKnowledge: this.selectedProfessionalKnowledge,
      };

      this.setAddressJob(this.localAddressJob);
    },
    async localOnSelectProfessionalSoftSkills(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        professionalSoftSkills: this.selectedProfessionalSoftSkills,
      };

      this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedProfessionalKnowledge = this.localAddressJob.professionalKnowledge;
      this.selectedProfessionalSoftSkills = this.localAddressJob.professionalSoftSkills;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form-check {
  align-items: center;
  display: flex;
  gap: 56px;
  justify-content: space-between;
  margin: 0;
  min-height: unset;
  padding: 8px 0;

  + .form-check {
    border-top: 1px solid $steel;
  }

  .form-check-input {
    float: unset;
    margin: 0;
  }

  .form-check-label {
    font-size: 16px;
    height: auto;
    text-align: left;
  }
}

.form-subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-block: 2rem 1rem;

  span {
    color: $pink-500;
    font-size: 16px;
    font-weight: 400;
  }
}

.invalid-message {
  bottom: 2rem;
  position: sticky;
}
</style>
