export interface LayoutNames {
  default: string;
  affiliate: string;

}

const layoutNames: Readonly<LayoutNames> = {
  default: 'Default',
  affiliate: 'Affiliate',
};

export default layoutNames;
