import { saveAs } from 'file-saver';
import { useToast } from 'vue-toast-notification';

export default function useQrCodeGetter() {
  const toast = useToast();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getQrCodeBlob = async (rawQrCode: boolean, addressUuid: string, type?: string): Promise<[Blob, string]> => {
    const addressEndPoint = rawQrCode ? 'qrcode_small' : 'qrcode';

    const response = await window.ky.get(`/api/addresses/${addressUuid}/${addressEndPoint}/?type=${type || ''}`);
    const fileNameHeader = 'x-suggested-filename';
    let fileName = 'qrcode.png';

    if (response.headers.get(fileNameHeader)) {
      fileName = response.headers.get(fileNameHeader);

      if (type) {
        fileName = `${type}_${fileName}`;
      }
    }

    const blob = await response.blob();
    return [blob, fileName];
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const downloadQrCode = async (rawQrCode: boolean, addressUuid: string, type?: string): Promise<void> => {
    try {
      const [blob, suggestedFileName] = await getQrCodeBlob(rawQrCode, addressUuid, type);
      saveAs(blob, suggestedFileName);
    } catch {
      toast.error('Failed to download QR code');
    }
  };

  return {
    getQrCodeBlob,
    downloadQrCode,
  };
}
