export default function addLayoutGlobally(app) {
  // eslint-disable-next-line unicorn/prefer-module
  const requireComponent = require.context(
    '.',
    false,
    /[\w-]+\.vue$/,
  );

  for (const fileName of requireComponent.keys()) {
    const componentConfig = requireComponent(fileName);
    const component = componentConfig.default || componentConfig;

    app.component(component.name, component);
  }
}
