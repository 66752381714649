<template>
  <div>
    <button

      type="button"
      aria-controls="offcanvas"
      class="d-block d-lg-none btn p-0 btn-offcanvas"
      @click="toggleMenu"
    >
      <v-sprite icon="burger-white" />
    </button>

    <div id="offcanvas" ref="offcanvas" class="offcanvas offcanvas-start" tabindex="-1">
      <div class="offcanvas-header">
        <v-app-logo />
        <button
          type="button"
          class="btn-close text-white align-self-start"
          aria-label="Close"
          @click="closeMenu" />
      </div>
      <div class="offcanvas-body">
        <v-app-navigation class="offcanvas-navigation" @close-menu="closeMenu" @open-modal="$emit('openModal')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppNavigationResponsive',
  emits: ['openModal'],

  data() {
    return {
      bsOffcanvas: null as (Offcanvas| null),
    };
  },
  mounted(): void {
    if (this.$refs.offcanvas) {
      this.bsOffcanvas = new Offcanvas((this.$refs.offcanvas as Element));
    }
  },
  methods: {
    toggleMenu(): void {
      if (this.bsOffcanvas) {
        this.bsOffcanvas.toggle();
      }
    },
    closeMenu(): void {
      this.bsOffcanvas?.hide();
    },

  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@include offcanvas();

.app-navigation {
  display: block;
}
</style>
