<template>
  <div class="resumes-shop-header">
    <h1 class="app-title">
      {{ $t(`views.resume_shop.title`) }} <span class="resumes-count">({{ resumesFilterCount }})</span>
    </h1>
    <div class="resumes-actions-container">
      <button
        v-for="(action, index) in actionsType"
        :key="index"
        type="button"
        class="btn"
        :class="{ 'btn-primary': action.active }"
        @click="selectAction(action);"
      >
        {{ $t(`views.resume_shop.${action.name}`) }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';

import ActionType from '../interfaces/action-type';

export default defineComponent({
  name: 'ResumeShopHeader',
  props: {
    actionsType: {
      type: Object as PropType<ActionType[]>,
      required: true,
    },
  },
  emits: ['update:actionsType'],
  computed: {
    ...mapState('resumeShop', [
      'resumesFilterCount',
    ]),
  },
  methods: {
    selectAction(action: ActionType): void {
      for (const act of this.actionsType) {
        act.active = action.name === act.name;
      }

      this.$emit('update:actionsType', this.actionsType);

      // Update "section" query parameter with the actionsType
      this.$router.push({ name: this.$route.name, query: { section: action.name } });
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-actions-container {
  background-color: $white;
  border-radius: 56px;
  display: flex;
  margin-top: 32px;
  min-height: 56px;
  padding: 8px;
  width: 100%;

  @include mq($from: mobile) {
    width: fit-content;
  }

  .btn {
    border-radius: 40px;
    flex-grow: 1;
    font-weight: 500;
    padding: 0 12px;

    &:not(.btn-primary) {
      color: $primary;
    }
  }
}
</style>
