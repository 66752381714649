<template>
  <div class="resumes-shop-list">
    <div class="resumes-header-filter">
      <v-resumes-job-filter
        v-model:selected-job="selectedJobFilter"
      />
    </div>
    <div v-if="groupedCandidatesBySearchedJob.length > 0" class="candidates-content">
      <p class="candidates-matching-title">
        Cliquez sur les profils pour accéder aux coordonnées des candidats
      </p>
      <div v-for="(jobGroup, index) in groupedCandidatesBySearchedJob" :key="index" class="candidates-group">
        <div class="candidates-group-header">
          <p class="title">{{ jobGroup.searchedJobName }}</p>
        </div>
        <div v-for="(dateGroup, dateIndex) in jobGroup.dateGroups" :key="dateIndex">
          <div class="candidates-group-header">
            <p class="date">Le {{ dateGroup.purchasedDate }} ({{ dateGroup.candidates.length }})</p>
          </div>
          <div class="candidates-grid">
            <v-candidate-card
              v-for="candidate in dateGroup.candidates"
              :key="candidate"
              :favorite="false"
              :basket="false"
              :candidate="candidate"
              @refresh-resumes="getMatchingPurchasedCandidateList()"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="candidates-content">
      <div class="missing-purchases">
        <h4 class="title">Vous n'avez pas encore acheté de CV</h4>
        <p class="subtitle">Ne laissez pas filer vos candidats favoris ou perles rares.</p>
        <img src="@/images/svg/missing-purchases.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DebouncedFunc, throttle } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapMutations } from 'vuex';

import { CandidateShopItem, GroupedBySearchJob } from '../interfaces/candidate-shop-item';
import Job from '../interfaces/job';
import PaginatedResult from '../interfaces/pagination';
import groupBySearchJobName from '../services/candidate-shopping';

export default defineComponent({
  name: 'ResumeShopPurchases',
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      loading: false,
      groupedCandidatesBySearchedJob: [] as GroupedBySearchJob[],
      page: 1,
      count: 0,
      totalPages: 2,
      pageSize: 5,
      previousPageNumber: null as (null | number),
      nextPageNumber: null as (null | number),
      lookupAddressHandler: throttle(this.getMatchingPurchasedCandidateList, 1000) as DebouncedFunc<()=> void>,
      selectedJobFilter: null as Job | null,
    };
  },
  computed: {
    queryParams(): string {
      const queryParameters = new URLSearchParams();
      queryParameters.append('page', this.page.toString());

      if (this.selectedJobFilter) {
        queryParameters.append('candidate__searched_jobs__job', this.selectedJobFilter.id.toString());
      }

      return queryParameters.toString();
    },
  },
  watch: {
    async queryParams(newValue: string, oldValue: string): Promise<void> {
      if (newValue !== oldValue) {
        this.setPage(this.page);
      }
    },
  },
  async mounted(): Promise<void> {
    await this.getMatchingPurchasedCandidateList();
    this.setResumesFilterCount(this.groupedCandidatesBySearchedJob.length);
  },
  methods: {
    ...mapMutations('resumeShop', [
      'setResumesFilterCount',
    ]),
    async getMatchingPurchasedCandidateList(): Promise<void> {
      let url = '/api/purchased-candidate/';

      if (this.queryParams.length > 0) {
        url += `?${this.queryParams.toString()}`;
      }

      try {
        const {
          count,
          totalPages,
          pageSize,
          previousPageNumber,
          nextPageNumber,
          results,
        } = await window.ky.get(url).json<PaginatedResult<CandidateShopItem>>() as PaginatedResult<CandidateShopItem>;
        if (results) {
          this.count = count;
          this.totalPages = totalPages;
          this.pageSize = pageSize;
          this.previousPageNumber = previousPageNumber;
          this.nextPageNumber = nextPageNumber;
          this.groupedCandidatesBySearchedJob = groupBySearchJobName(results);
        }
      } catch {
        this.toast.error(($t('views.candidates.error') as string));
      }
      this.loading = false;
    },
    setPage(page: number): void {
      this.page = page;
      this.lookupAddressHandler();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.missing-purchases {
  color: $body-color;
  margin-inline: auto;
  max-width: 100%;
  padding: 20px;
  text-align: center;
  width: 400px;

  @include mq($from: mobile) {
    padding-inline: 0;
  }

  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .subtitle {
    margin-bottom: 32px;
  }
}
</style>
