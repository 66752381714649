/* eslint-disable unicorn/consistent-function-scoping */
import Cookies from 'js-cookie';
// import VueRouter, { Route, RouteConfig } from 'vue-router';
import { createRouter as _createRouter, createWebHistory, Route } from 'vue-router';

import routesNames from '@/js/router/route-names';
import State from '@/js/store/state';

import Recruiter from '../interfaces/recruiter';
import createStore from '../store/index';
import affiliateRoutes from './affiliate-routes';
import recruiterRoutes from './recruiter-routes';

const store = createStore();

export default function createRouter() {
  const router = _createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/app',
        name: routesNames.home,
        meta: { anonymousOnly: true },
        redirect: '/app/login',
      },
      {
        path: '/app/login',
        name: routesNames.login,
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-login' },
      },
      {
        path: '/app/lost-password',
        name: routesNames.lostPassword,
        component: () => import(/* webpackChunkName: "lost-password" */ '../views/LostPassword.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-lost-password' },
      },
      {
        path: '/app/lost-password/confirm',
        name: routesNames.lostPasswordConfirm,
        component: () => import(/* webpackChunkName: "lost-password-confirm" */ '../views/LostPasswordConfirm.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-lost-password' },
      },
      {
        path: '/:catchAll(.*)',
        name: routesNames.pageNotFound,
        component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'),
        meta: { needLayout: false },
      },
      ...affiliateRoutes,
      ...recruiterRoutes,
    ],
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  function checkUserAndRedirect(to: Route, next: Function) {
    const shouldRedirectNonPremium = (user: Recruiter): boolean => !user.recruiter.premium && !user.recruiter.hasPayedOrders;
    const shouldRedirectAffiliate = (user: Recruiter): boolean => user.recruiter.affiliate && !user.recruiter.hasPayedOrders;
    const isHeadhunter = (user: Recruiter): boolean => user.recruiter.headhunter;

    const isRestrictedRoute = (to: Route): boolean => {
      const whitelistedRoutes = ['AddNewAddress', 'RegisterStep2', 'RegisterSuccess', 'Reset'];
      return to.name && !whitelistedRoutes.includes(to.name) && !to.query.checkout_session_id;
    };

    const { user } = (store.state as State).recruiter;
    if (
      user
      && shouldRedirectNonPremium(user)
      && shouldRedirectAffiliate(user)
      && !isHeadhunter(user)
      && isRestrictedRoute(to)
    ) {
      next({ name: routesNames.reset, query: { redirect: to.fullPath } });
    } else {
      next();
    }
  }

  const requiresAuthGuard = async (to: Route, from: Route, next: Function) => {
    const accessToken = Cookies.get('jdmcv_access');
    const isLoggedIn = !!accessToken;

    if (isLoggedIn) {
      const { user } = (store.state as State).recruiter;

      if (!user) {
        try {
          await store.dispatch('recruiter/getProfile');
        } catch (error) {
          console.error(error);
        }
      }

      checkUserAndRedirect(to, next);
    } else {
      next({
        name: routesNames.login,
        query: { redirect: to.fullPath },
      });
    }
  };

  const anonymousOnlyGuard = (to: Route, from: Route, next: Function) => {
    const isAnonymous = !(store.state as State).recruiter.user;

    if (isAnonymous) {
      next();
    } else {
      next({
        name: routesNames.dashboard,
      });
    }
  };

  // This router guard redirect the user to the login or dashboard,
  // depending on its connected state
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      await requiresAuthGuard(to, from, next);
    } else if (to.matched.some((record) => record.meta.anonymousOnly)) {
      anonymousOnlyGuard(to, from, next);
    } else {
      next();
    }
  });

  // This router guard add body classes on route navigation
  router.afterEach((to, from) => {
    const oldBodyClass: string = from.meta?.bodyClass;
    const newBodyClass: string = to.meta?.bodyClass;

    if (oldBodyClass && document.body.classList.contains(oldBodyClass)) {
      document.body.classList.remove(oldBodyClass);
    }

    if (newBodyClass && !document.body.classList.contains(newBodyClass)) {
      document.body.classList.add(newBodyClass);
    }
  });

  return router;
}
