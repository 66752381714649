<template>
  <div>
    <template v-if="'needLayout' in $route.meta && $route.meta.needLayout === true">
      <AppLayout />
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import AppLayout from './layouts/AppLayout.vue';

export default defineComponent({
  name: 'App',
  components: { AppLayout },
});
</script>
