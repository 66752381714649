<template>
  <div class="order-affiliate-kit-infos">
    <h1 class="title" v-html="$t('components.order_affiliate_kit_infos.title')" />

    <div class="kit-infos">
      <button type="button" class="kit-toggle" :class="{ open: isOpen }" @click="toggle()">
        {{ $t('components.order_affiliate_kit_infos.kit_list_title') }}
        <v-sprite class="toggle-icon" color="var(--bs-primary)" icon="dropdown_arrow" />
      </button>
      <div class="kit-content" :class="{ visible: isOpen }">
        <ul class="kit-items">
          <li class="kit-item">
            <v-sprite color="var(--bs-primary)" icon="check" />
            {{ $t('components.order_affiliate_kit_infos.list_item_1') }}
          </li>
          <li class="kit-item">
            <v-sprite color="var(--bs-primary)" icon="check" />
            {{ $t('components.order_affiliate_kit_infos.list_item_2') }}
          </li>
          <li class="kit-item">
            <v-sprite color="var(--bs-primary)" icon="check" />
            {{ $t('components.order_affiliate_kit_infos.list_item_3') }}
          </li>
          <li class="kit-item">
            <v-sprite color="var(--bs-primary)" icon="check" />
            {{ $t('components.order_affiliate_kit_infos.list_item_4') }}
          </li>
        </ul>

        <div class="invalid-message">
          <v-sprite color="var(--bs-pink)" icon="info" />
          {{ $t('components.order_affiliate_kit_infos.warning_info') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderAffiliateKitInfos',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.order-affiliate-kit-infos {
  .title {
    color: $pink-700;
    font-size: 24px;
    font-weight: 700;

    @include mq($from: desktop) {
      font-size: 32px;
      margin-top: 64px;
    }
  }
}

.kit-infos {
  background-color: $white;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px;

  @include mq($from: desktop) {
    background-color: transparent;
    padding: 0;
  }
}

.kit-toggle[type=button] {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $primary;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
  padding: 0;
  width: 100%;

  @include mq($from: desktop) {
    cursor: default;
  }

  .toggle-icon {
    height: 13px;
    transition: all .3s;
    width: 20px;

    @include mq($from: desktop) {
      display: none;
    }
  }

  &.open {
    .toggle-icon {
      transform: rotate(180deg);
    }
  }
}

.kit-content {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }

  &.visible {
    display: block;
  }
}

.kit-items {
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;
}

.kit-item {
  align-items: center;
  display: grid;
  gap: 11px;
  grid-template-columns: 13px minmax(0, 1fr);
  line-height: 1em;

  + .kit-item {
    margin-top: 8px;
  }

  svg {
    width: 13px;
  }
}

.invalid-message {
  align-items: start;
  color: $body-color;
  margin-top: 16px;
}
</style>
